/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, Subtitle, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Řez"} description={"Jedna ze tří hlavních služeb"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"hlavni_rez"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2 mt--10 pb--10" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="--justify pb--25" style={{"maxWidth":866,"paddingBottom":null}}>
              
              <Text className="swp-T00-mobile text-box fs--30 w--500 mt--02" style={{"maxWidth":534,"marginTop":null}} content={"Řez ovocných stromků<br>"}>
              </Text>

              <Subtitle className="subtitle-box mt--25" style={{"marginTop":null}} content={"<span style=\"color: rgb(142, 146, 172);\">Můžete si objednat letní, zimní, zmlazovací řez ovocných stromů.<br></span>"}>
              </Subtitle>

              <Text className="text-box fs--20 w--500" style={{"maxWidth":581}} content={"<b>Nyní je ještě vhodná doba pro letní řez !<br></b>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":609}} content={"<b>Řez ovocných stromů - od 300,- do 1 500,- Kč/ks<br>Zmlazovací řez&nbsp; - od 400 do 3 000 Kč/ks<br>Konkrétní cena je závislá na velikosti stromu a jeho stavu. Součástí řezu je v případě potřeby i ošetření kmene, ochrana proti nemocem.<br></b>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/19015/0f168fe7d174457ea04961ed78798d44_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19015/0f168fe7d174457ea04961ed78798d44_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/0f168fe7d174457ea04961ed78798d44_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/0f168fe7d174457ea04961ed78798d44_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/0f168fe7d174457ea04961ed78798d44_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19015/0f168fe7d174457ea04961ed78798d44_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--justify pb--25" style={{"maxWidth":866,"paddingBottom":null}}>
              
              <Text className="swp-T00-mobile text-box mt--02" style={{"maxWidth":280,"marginTop":null}} content={"<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":488}} content={"<b>...</b>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/19015/ce70de5087a84e65b740ab795f4e998e_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19015/ce70de5087a84e65b740ab795f4e998e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/ce70de5087a84e65b740ab795f4e998e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/ce70de5087a84e65b740ab795f4e998e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/ce70de5087a84e65b740ab795f4e998e_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"uqqigow219"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}